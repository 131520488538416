/* You can add global styles to this file, and also import other style files */

$theme-colors: ("primary": #174400, "secondary": green, "success": #28a745, "info": #17a2b8, "warning": #ffc107, "danger": #dc3545, "light": #f8f9fa, "dark": #343a40)

$nav-pills-link-active-color: white
$nav-pills-link-active-bg: green
$nav-link-color: white

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap'

html, body
  height: 100%
  margin: 0

.loader
  display: block
  position: absolute
  top: 50%
  left: 50%
  z-index: 999

.asterix-after:after
  padding-left: 3px
  content: "*"
  color: red

.textarea-wrapper
  position: relative

.textarea-counter
  text-align: center
  color: white
  position: absolute
  bottom: -40px
  right: 15px
  display: none
  background-color: #174400
  padding: 4px 8px
  border-radius: 5px

.textarea-wrapper textarea:focus + .textarea-counter,
.textarea-wrapper input:focus + .textarea-counter
  display: block

th[sortable]
  cursor: pointer
  user-select: none
  -webkit-user-select: none


th[sortable].desc:before,
th[sortable].asc:before
  content: ''
  display: block
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC') no-repeat
  background-size: 22px
  width: 22px
  height: 22px
  float: left
  margin-left: -22px


th[sortable].desc:before
  transform: rotate(180deg)
  -ms-transform: rotate(180deg)

.inline-list
  list-style: none
  display: inline
  padding-left: 0

.inline-list > li:after
  content: ", "

.inline-list > li:last-child:after
  content: ""

.inline-list > li
  display: inline

.button-margin
  margin-left: 10px
  margin-right: 10px

.filter-input-container
  display: flex
  justify-content: center
  flex-direction: row
  align-items: flex-end
  margin-bottom: 10px
  flex-wrap: wrap
  gap: 10px
  row-gap: 10px

.form-list
  padding-left: 0

.invalid-feedback-text
  display: block
  width: 100%
  margin-top: .25rem
  font-size: 0.875em
  color: var(--bs-form-invalid-color)

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap'

.fake-disabled
  color: $input-disabled-color
  background-color: $input-disabled-bg
  border-color: $input-disabled-border-color
  opacity: 1
